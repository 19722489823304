import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { wrapper } from '../api/store'
import { Provider } from 'react-redux'
import * as gtag from '../libs/utils/ga'
import '../static/styles/globals.scss'

/**
 * MyApp
 * @param {any} Component
 * @param {any} pageProps
 * @return {ReactElement}
 */
export default function JOTA_UNICO({ Component, pageProps }: AppProps) {
    const router = useRouter()
    const { store } = wrapper.useWrappedStore(pageProps)

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            gtag.pageview(url)
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    return (
        <Provider store={store}>
            <Component {...pageProps} />
        </Provider>
    )
}
