/* eslint-disable max-len */
export const SITE_NAME =
    'JOTA – Jornalismo e tecnologia para tomadores de decisão'
export const SITE_DESCRIPTION =
    'Informação, notícias e análises sobre Judiciário, política, projetos de lei, direito tributário, regulação. Bastidores e previsibilidade para tomadores de decisão'
export const SITE_URL = 'https://jota.info'
export const UNDEFINED = 'undefined'
export const JU_TOKEN = 'JU_TOKEN'
export const RD_BRICK = 'bricks--component-embeddable-form'
export const RD_BRICK_ITEM = 'bricks-form__fieldset'
export const PRO_TRIBUTOS = 'tributos'
export const PRO_PODER = 'poder'
export const PRO_SAUDE = 'saúde'
export const SITE_KEYWORDS_DEFAULT =
    'notícias, últimas notícias, jornalismo, atualidades, política, economia, tecnologia, saúde, notícias de hoje, cobertura ao vivo, reportagens'

export const PRO_PODER_LOGIN_TEXT_1 =
    ' Com o JOTA PRO Poder, você e sua empresa estarão sempre um passo à frente no acompanhamento dos Três Poderes.'

export const PRO_PODER_LOGIN_TEXT_2 =
    'Experimente grátis e tenha o melhor da análise e da inteligência política na palma da sua mão, com relatórios, alertas, calls e muito mais.'

export const PRO_TRIBUTOS_LOGIN_TEXT_1 =
    'Com o JOTA PRO Tributos, você e sua empresa estarão sempre um passo à frente nas mudanças no contencioso tributário nacional.'

export const PRO_TRIBUTOS_LOGIN_TEXT_2 =
    ' Experimente grátis e acompanhe os bastidores das discussões tributárias mais relevantes de Carf, STF, STJ e Congresso.'

export const SCROLL_LIMIT_NORMAL = 65
export const SCROLL_POSITION_LIMIT = 90
